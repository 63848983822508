.wrapper {
  background-color: #fff;
  padding: 20px 5px 15px 40px;

  .sub-component-title {
    font-size: 14px;
    color: #2C2E35;
  }

  .sub-component-container {
    display: grid;
    grid-template-columns: auto 65px;

    .sub-component-add-icon {
      margin: 0 auto;
      padding-top: 10px;
    }

    .no-data-yet {
      color: #8f939b;
      font-weight: 500;
      letter-spacing: 0.48px;
      line-height: 3;
    }

    table {
      min-height: 60px;
      border-spacing: 2px;
      border-collapse: initial;
      background: #F2F2F2;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 60px;

      thead tr th {
        color: #2C2E357F;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.48px;
        border: none;

        &:first-child {
          width: 300px;
        }

        &:nth-child(2) {
          width: 300px;
        }
      }

      tbody tr td {
        color: #2C2E35;
        border: none;

        &.sub-component-delete {
          text-align: end;
          padding-right: 10px;
        }
      }
    }

    .deactivated-td {
      color: #f4516c;
    }
  }
}

.zev-zoho-link {
  color: var(--primary-color) !important;

  &:hover {
    text-decoration: underline;
  }
}

.sub-meter-container {
  .nav-link.m-tabs__link {
    color: #fff !important;

    &:hover {
      color: #fff;
      border-bottom: none !important;
    }
  }
}
